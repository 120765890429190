<template>
  <b-row>
    <b-col
      md="12"
    >
      <b-card-code
        title="Offres annulées"
        no-body
      >
        <b-card-body>
          <div class="d-flex justify-content-between  flex-wrap">
            <!-- sorting  -->
            <b-form-group
              label="Trier"
              label-size="sm"
              label-align-sm="left"
              label-cols-sm="2"
              label-for="sortBySelect"
              class=" mb-md-0"
            >
              <b-input-group
                size="sm"
              >
                <b-form-select
                  id="sortBySelect"
                  v-model="sortBy"
                  :options="sortOptions"
                >
                  <template #first>
                    <option value="">
                      aucun
                    </option>
                  </template>
                </b-form-select>
                <b-form-select
                  v-model="sortDesc"
                  size="sm"
                  :disabled="!sortBy"
                >
                  <option :value="false">
                    Croissant
                  </option>
                  <option :value="true">
                    Décroissant
                  </option>
                </b-form-select>
              </b-input-group>
            </b-form-group>

            <!-- filter -->
            <b-form-group
              label="Filtrer"
              label-cols-sm="2"
              label-align-sm="left"
              label-size="sm"
              label-for="filterInput"
              class="mb-0"
            >
              <b-input-group size="sm">
                <b-form-input
                  id="filterInput"
                  v-model="filter"
                  type="search"
                  placeholder="Rechercher"
                />
                <b-input-group-append>
                  <b-button
                    :disabled="!filter"
                    @click="filter = ''"
                  >
                    Effacer
                  </b-button>
                </b-input-group-append>
              </b-input-group>
            </b-form-group>
          </div>
        </b-card-body>

        <b-table
          striped
          hover
          responsive
          small
          class="position-relative"
          :per-page="perPage"
          :current-page="currentPage"
          :items="items"
          :fields="fields"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          :sort-direction="sortDirection"
          :filter="filter"
          :filter-included-fields="filterOn"
          @filtered="onFiltered"
        >
          <!-- Column: Actions -->
          <template #cell(actions)>
            <b-dropdown
              variant="link"
              no-caret
            >

              <template #button-content>
                <feather-icon
                  icon="MoreVerticalIcon"
                  size="16"
                  class="align-middle text-body"
                />
              </template>
              <b-dropdown-item>
                <feather-icon icon="FileTextIcon" />
                <span class="align-middle ml-50">Details</span>
              </b-dropdown-item>

              <b-dropdown-item>
                <feather-icon icon="EditIcon" />
                <span class="align-middle ml-50">Edit</span>
              </b-dropdown-item>

              <b-dropdown-item>
                <feather-icon icon="TrashIcon" />
                <span class="align-middle ml-50">Delete</span>
              </b-dropdown-item>
            </b-dropdown>
          </template>
        </b-table>

        <b-card-body class="d-flex justify-content-between flex-wrap pt-0">

          <!-- page length -->
          <b-form-group
            label="Par Page"
            label-cols="6"
            label-align="left"
            label-size="sm"
            label-for="sortBySelect"
            class="text-nowrap mb-md-0 mr-1"
          >
            <b-form-select
              id="perPageSelect"
              v-model="perPage"
              size="sm"
              inline
              :options="pageOptions"
            />
          </b-form-group>

          <!-- pagination -->
          <div>
            <b-pagination
              v-model="currentPage"
              :total-rows="totalRows"
              :per-page="perPage"
              first-number
              last-number
              prev-class="prev-item"
              next-class="next-item"
              class="mb-0 pagination-center"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </div>
        </b-card-body>

      </b-card-code>
    </b-col>

  </b-row>
</template>

<script>
import Ripple from 'vue-ripple-directive'

import BCardCode from '@core/components/b-card-code/BCardCode.vue'
import {
  BRow, BCol, BTable, VBTooltip, BFormGroup, BFormSelect, BPagination, BInputGroup, BInputGroupAppend, BButton, BCardBody, BDropdown, BDropdownItem, BFormInput,
} from 'bootstrap-vue'

export default {
  components: {
    BRow,
    BCol,
    BCardCode,
    BTable,
    BFormGroup,
    BFormSelect,
    BPagination,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BButton,
    BCardBody,
    BDropdown,
    BDropdownItem,

  },
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },
  data() {
    return {
      perPage: 5,
      pageOptions: [3, 5, 10],
      totalRows: 1,
      currentPage: 1,
      sortBy: '',
      sortDesc: false,
      sortDirection: 'asc',
      filter: null,
      filterOn: [],
      infoModal: {
        id: 'info-modal',
        title: '',
        content: '',
      },
      fields: [
        { key: 'index', label: '' },
        { key: 'code', label: 'CODE' },
        { key: 'adresseDepart', label: 'DEPART', sortable: true },
        { key: 'depart', label: 'DTE DEPART' },
        { key: 'adresseDestination', label: 'DESTINATION' },
        { key: 'arrive', label: 'DTE ARRIVE' },
        { key: 'actions', label: 'ACTION' },
      ],
      /* eslint-disable global-require */
      items: [
        {
          code: 'EBDO0000000024',
          adresseDepart: 'ZONGO',
          depart: '07/07/2022 12:00',
          adresseDestination: 'AGAMADIN, LOT 113',
          arrive: '06/07/2022 12:01',
        },
        {
          code: 'EBDO0000000024',
          adresseDepart: 'ZONGO',
          depart: '07/07/2022 12:00',
          adresseDestination: 'AGAMADIN, LOT 113',
          arrive: '06/07/2022 12:01',
        },
        {
          code: 'EBDO0000000024',
          adresseDepart: 'ZONGO',
          depart: '07/07/2022 12:00',
          adresseDestination: 'AGAMADIN, LOT 113',
          arrive: '06/07/2022 12:01',
        },
        {
          code: 'EBDO0000000024',
          adresseDepart: 'ZONGO',
          depart: '07/07/2022 12:00',
          adresseDestination: 'AGAMADIN, LOT 113',
          arrive: '06/07/2022 12:01',
        },
        {
          code: 'EBDO0000000024',
          adresseDepart: 'ZONGO',
          depart: '07/07/2022 12:00',
          adresseDestination: 'AGAMADIN, LOT 113',
          arrive: '06/07/2022 12:01',
        },
        {
          code: 'EBDO0000000024',
          adresseDepart: 'ZONGO',
          depart: '07/07/2022 12:00',
          adresseDestination: 'AGAMADIN, LOT 113',
          arrive: '06/07/2022 12:01',
        },
        {
          code: 'EBDO0000000024',
          adresseDepart: 'ZONGO',
          depart: '07/07/2022 12:00',
          adresseDestination: 'AGAMADIN, LOT 113',
          arrive: '06/07/2022 12:01',
        },
        {
          code: 'EBDO0000000024',
          adresseDepart: 'ZONGO',
          depart: '07/07/2022 12:00',
          adresseDestination: 'AGAMADIN, LOT 113',
          arrive: '06/07/2022 12:01',
        },
        {
          code: 'EBDO0000000024',
          adresseDepart: 'ZONGO',
          depart: '07/07/2022 12:00',
          adresseDestination: 'AGAMADIN, LOT 113',
          arrive: '06/07/2022 12:01',
        },
        {
          code: 'EBDO0000000024',
          adresseDepart: 'ZONGO',
          depart: '07/07/2022 12:00',
          adresseDestination: 'AGAMADIN, LOT 113',
          arrive: '06/07/2022 12:01',
        },
        {
          code: 'EBDO0000000024',
          adresseDepart: 'ZONGO',
          depart: '07/07/2022 12:00',
          adresseDestination: 'AGAMADIN, LOT 113',
          arrive: '06/07/2022 12:01',
        },
        {
          code: 'EBDO0000000024',
          adresseDepart: 'ZONGO',
          depart: '07/07/2022 12:00',
          adresseDestination: 'AGAMADIN, LOT 113',
          arrive: '06/07/2022 12:01',
        },
      ],
    }
  },
  computed: {
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter(f => f.sortable)
        .map(f => ({ text: f.label, value: f.key }))
    },
  },
  mounted() {
    // Set the initial number of items
    this.totalRows = this.items.length
  },
  methods: {
    info(item, index, button) {
      this.infoModal.title = `Row index: ${index}`
      this.infoModal.content = JSON.stringify(item, null, 2)
      this.$root.$emit('bv::show::modal', this.infoModal.id, button)
    },
    resetInfoModal() {
      this.infoModal.title = ''
      this.infoModal.content = ''
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },
  },
}
</script>
